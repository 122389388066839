/* slick-custom.css */
.slick-dots li button:before {
    color: #CED9DE; /* Change the dot color */
    opacity: 0.9;
    
}

.slick-prev, .slick-next {
    z-index: 1; /* Ensure arrows are on top of the image */
}

.slick-prev:before, .slick-next:before {
    color: #CED9DE; /* Change arrow color */
    opacity: 0.7;
}

/* Position the arrows inside the image */
.slick-prev {
    left: 10px;
}

.slick-next {
    right: 10px;
}